<footer class="footer-area degrade-amarillo">
    <div class="container">
        <div class="text-start"><img src="assets/img/RaiOn_Logo.svg" alt="RaiOn"  width="90" class="img-raion">
        </div>
        <div class="tabla-footer">
            <nav [class.active]="classApplied">
        <ul>
            <li class="tabla"><p><span style="color: #FBBD2E;">©RaiOn Energy 2022</span><br><br><br><br><br><br><br><br></p></li>
            <li><p class=""> SiteMap: <br><br>
                <u><a><span class="nav-link pading-foot" (click)="onClick('home')">Home</span></a></u>
                <u><a><span class="nav-link pading-foot" (click)="onClick('our-products')">Products</span></a></u>
                <u><a><span class="nav-link pading-foot" (click)="onClick('about-us')">About Us</span></a></u>
                <u><a><span class="nav-link pading-foot" (click)="onClick('contact')">Contact</span><br></a></u>
                <u><a><span class="" style="color:#FBBD2E" (click)="onClick('downloads')">Downloads</span></a></u>
            </p></li>
            <li><p class="ofice">Office:<br><br><br><br> Strathmore Energy Research Centre,<br> P.O.Box 59857 <br> Madaraka Estate, Nairobi, Kenya.<br><br><br><br></p></li>
            <li class="tabla-2"><div class="mapouter "><div class="gmap_canvas "><iframe width="249" height="249" id="gmap_canvas" class="" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAWNYDJvcWy0pIcDgLqw8SqLvGSwz3pG08&q=Strathmore%20Energy%20Research%20Centre,%20P.O.Box%2059857%20Madaraka%20Estate,%20Nairobi,%20Kenya.&center=-1.3102355469777822,36.815336726649306
                " frameborder="0" scrolling="no" allowfullscreen=””  marginheight="0" marginwidth="0"></iframe><br><style>.mapouter{position:relative;text-align:right;height:249px;width:249px;}</style><style>.gmap_canvas {overflow:hidden;background:none!important;height:249px;width:249px;}</style></div></div></li>
            
        </ul>
    </nav>
    </div>
    </div>
    <img src="assets/img/fondo_amarillo.png" alt="">


</footer>


<ngx-scrolltop></ngx-scrolltop>