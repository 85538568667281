import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(public http: HttpClient) { }

    public sendForm(datos:any): Observable<any[]> {
      return this.http
        .post<any[]>(`/api/resource/Contact%20Form%20Raion`, datos);
    }
}
