<div class=" bg-fffdfd  pt-100 pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <p>Our products are fully compatible with BYD.</p>
        </div>
        <div class="col-lg-6 welcome-title2">
            <p> BYD is the world market leader in energy storage. Our inverters are compatible with the following BYD products. <br><br><br> <button class="Discover-btn">
                <a class="href" href='https://www.bydbatterybox.com' target="_blank"><span style="color: #FBBD2E;">Discover BYD ></span></a>
            </button></p>
        </div>
    </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-img">
                            <a ></a>
                            <img src="assets/img/PREMIUM LVS.png" height="300" alt="services-img">
                            
                        </div>
                        <div class="services-content">
                            <h3>Premium LVS(4-256 kWh)</h3>
                            <button class="btn-tecnhnical"><a class="href" href='https://www.bydbatterybox.com/uploads/downloads/201013_Premium_Datasheet_LVS%20V2.1%20EN-5fa4baa72098c.pdf' target="_blank">Technical Data Sheet ></a></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-img">
                            <img src="assets/img/PREMIUM LVL.png" height="300px" alt="services-img">
                            
                        </div>
                        <div class="services-content">
                            <h3>Premium LVL (15.4-93 kWh)</h3>
                            <button class="btn-tecnhnical"><a class="href" href='https://www.bydbatterybox.com/uploads/downloads/201112_Premium_Datasheet_LVL%20V1.1%20EN-5fb7211c160e1.pdf' target="_blank">Technical Data Sheet ></a></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="services-img">
                            <img src="assets/img/LV FLEX LITE.png" width="300px" height="300px" alt="services-img">
                        </div>
                        <div class="services-content">
                            <h3>LVL Flex Lite (5-320kWh)</h3>
                            <button class="btn-tecnhnical"><a class="href" href='https://www.bydbatterybox.com/uploads/downloads/BYD_Battery-Box_LV_Flex_Datasheet_V1.5-62c38cdeea756.pdf' target="_blank">Technical Data Sheet ></a></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="nutshell-title"><p>raiON in a nutshell</p></div>
                <div class="col-lg-3 col-md-6">
                    <div class="services-content single-services">
                        <div class="services-img"><br><br>

                            <img src="assets/img/PARALLEL CONNECTION.png" height="300" alt="services-img">
                            
                        </div>
                        <div class="services-content">

                            <h3>PARALLEL CONNECTION</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="services-content single-services">
                        <div class="services-img"><br><br>
                            <img src="assets/img/ON-GRID OR OFF-GRID.png" height="300px" alt="services-img">
                            
                        </div>
                        <div class="services-content">
                            <h3>ON-GRID OR OFF-GRID</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services">
                        <div class="services-img"><br><br>
                            <img src="assets/img/CAPACITY.png" width="300px" height="300px" alt="services-img">
                        </div>
                        <div class="services-content">
                            <h3>5KWH OR 8KWH CAPACITY</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services">
                        <div class="services-img"><br><br>
                            <img src="assets/img/HYBRID SYSTEM.png" width="300px" height="300px" alt="services-img">
                        </div>
                        <div class="services-content">
                            <h3>HYBRID SYSTEM</h3>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</div>