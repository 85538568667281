<div id="contact" class=" ptb-100 degrade bg-F7F5F4">
    <div class="container">
        <div class="section">
            <p class="section-title"><span style="color: #FBBD2E;">CONTACT US</span></p>
            <div class="text-center about-us-p"><p>We love to help and advise new people.</p></div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false">
                                           <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Your name</label>
                                    <input  minlength="3" maxlength="50" formControlName="full_name" type="text" name="full_name" class="form-control" id="name"  required>
                                    <div *ngIf="contactForm.controls['full_name'].invalid && (contactForm.controls['full_name'].dirty || contactForm.controls['full_name'].touched || isSubmitted)" class="alert">
                                        <small class="text-danger" *ngIf="contactForm.controls['full_name'].errors.required">
                                        Please enter Your name
                                        </small>
                                    </div>                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Your email</label>
                                    <input formControlName="email" type="text"  class="form-control" id="email">
                                    <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                                        <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                                        Please enter Your email
                                        </small>
                                        <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                                            Please enter a valid Email
                                            </small>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Your company name</label>
                                    <input formControlName="company_name" type="text"  class="form-control" id="company_name">
                                    <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                                        <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                                        Please enter Your company name
                                        </small>
                                        
                                    </div> 
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Type your message here…</label>
                                    <textarea formControlName="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
                                        <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
                                        Please enter Type your message
                                        </small>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="btn btn-primary" *ngIf="!formLoading" >Send Message</button>
                                <img src="assets/img/giphy.gif" style="width: 50px; margin:auto" *ngIf="formLoading">    
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="formularioEnviado == true">
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group mb-3 text-center">
                                <p>Your message has been send successfully.</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="far fa-envelope"></i> <a href="mailto:sales@raion.energy"><span>Email:</span>sales@raion.energy</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:+254207640823"><span>Call:</span>+254 207 640 823</a></li>
                                <li><i class="fas fa-envelope"></i> <a href="mailto:service@raion.energy"><span>For service</span>service@raion.energy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>