<div class=" degrade bg-F7F5F4 pt-100" id="about-us">
    <div class="container">
        <div class="section">
            <p class="section-title"><span style="color:#FBBD2E">ABOUT US</span></p>
            <p class="about-us-p ">A lot more than an inverter brand.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <p>At raiOn, we transform solar rays in electrical current. However, behind this simple fact, there is something much bigger. A purpose that drives us forward. And that purpose is contributing to the transformation towards a world without fossil fuels. <br><br> Our way of contributing to the cause is bringing clean affordable energy wherever it is needed most.                             <br><br>
                                </p>
                            
                            </div>
                            <!-- <p><span style="color:#FBBD2E">More Information > </span></p> -->
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">                           

                                <img src="assets/img/Raion_AboutUs.jpg" alt="img">
                            </div>
                        </div>
                        <div class="  bg-F7F5F4 pt-100" id="about-us">

                            <div class="section">
                            <div class="col-lg-6 welcome-title3 values-2">
                                <p>Our values</p>
                            </div>
                            <div class="parrafos or-values why-opacity">
                            <div class="col-lg-6 col-md-12 welcome-title3">
                                <p>Human technology</p>
                            </div>
                            <div class="col-lg-6 col-md-12 welcome-title6">
                                <p>It’s not about knowing more, it’s about understanding better. We carefully listen to the needs of underserved communities, and we translate those needs into solutions through technology. We make the technical simple, the expensive affordable and the fragile robust.</p>
                            </div>
                        </div>
                        <div class="parrafos or-values-2">
                            <div class="col-lg-6 col-sm-12 welcome-title3">
                                <p>Active involment</p>
                            </div>
                            <br>
                            <div class="col-lg-6 col-sm-12 welcome-title6">
                                <p>Understanding is the first step, but getting involved in the change is the key to achieving the challenge. Our effort doesn’t end with the development of the products you need, it starts there. And follows with a service that is right there for you whenever needed, ready to help you make the most of what our product has to offer.</p>
                            </div>
                        </div>
                        <div class="parrafos or-values-3">
                            <div class="col-lg-6 col-sm-12 welcome-title3">
                                <p>Smart Work</p>
                            </div>
                            <div class="col-lg-6 col-sm-12 welcome-title6">
                                <p>We believe in leveraging partnerships, focusing on what we do best and not reinventing the wheel so we can provide the most competitive solutions tailored to the needs of our customers. We work smarter rather than harder.</p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>