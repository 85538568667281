<div class="strategy-area bg-fffdfd degrade" id="our-products">
    <div class="container">
        <div class="row strategy-title degrade bg-fffdfd pt-100">
            <div class="section">
                <p class="text-center"><span style="color:#FBBD2E">OUR PRODUCTS</span></p>
                <p class="about-us-p2 text-center ">raiOn Inverters for a new era.</p>
                <div class="text-center"><p>Two different product ranges according to your needs. <br><br>
                    <input class="down-btn-2" (click)="toggleClass(); onClick('rion-j5')" type="image" src="assets/img/icono_scroll.png">
                </p>
            </div>
            <br>
            <br>
        </div>
            
            <div class="col-lg-6 col-md-12 container-fluid" id="rion-j5">
                <div class="strategy-content bg-azul ptb-70">
                    <div class="section-title">
                        <h4 class="strategy-content-t1">RaiOn J5</h4>
                        <h2 class="strategy-content-t2">5KW HYBRID INVERTER</h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Off grid & On grid function</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>UPS functionality with transfer time</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Removable LCD screen for convenient installation</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Built-in Wi-Fi for mobile monitoring</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Up to 9 units in parallel</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Compatible with BYD Battery-Box batteries</li>
                    </ul>
                    <a href="/assets/pdf/RAION_J5_Datasheet.pdf" download="RAION_J5" ><button class="technical-btn" style="color: #ffffff;">TECHINICAL DATA SHEET <i class="fa fa-download"></i></button><br><br><br></a>
                    
                    <input class="nav-link down-btn" (click)="toggleClass(); onClick('raion-m8')" type="image" src="assets/img/icono_scroll.png" >

                </div>
            </div>
            <div class="col-lg-6 col-md-12 bg-fffdfd">
                <div class="strategy-image">
                    <img src="assets/img/Raion_J5.png" alt="">
                </div>
            </div>
        </div>
        <div class="br-s"></div>
        <div class="row">
            <div class="col-lg-6 col-md-12 container-fluid" id="raion-m8">
                <div class="strategy-content bg-azul ptb-70">
                    <div class="section-title">
                        <h4 class="strategy-content-t1">RaiOn M8</h4>
                        <h2 class="strategy-content-t2">8KW HYBRID INVERTER</h2>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Off Grid & On grid function</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Status indication with RGB lights</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Built-in Wi-Fi for mobile monitoring</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Up to 6 units in parallel</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>Compatible with BYD Battery-Box batteries</li>
                        <li><i class="fa fa-circle"style="font-size:8px"></i>2 MPPT inputs (4000W x 2)</li>
                    </ul>
                    <a href="/assets/pdf/RAION_M8_Datasheet.pdf" download="RAION_M8"><button class="technical-btn" style="color: #ffffff;">TECHINICAL DATA SHEET <i class="fa fa-download"></i></button><br><br><br></a>
                    <input class="nav-link down-btn" (click)="toggleClass(); onClick('welcome-raion')"  type="image" src="assets/img/icono_scroll.png" ></div>
            </div>
            <div class="col-lg-6 col-md-12 bg-fffdfd">
                <div class="strategy-image">
                    <img src="assets/img/Raion_M8.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>