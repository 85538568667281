<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>WELCOME TO RAION</h4>
                    <h1>Simple, robust and local.</h1>
                    <p>An inverter developed with your communities’ needs in mind. <br><br>
                        <input class="down-btn" (click)="toggleClass(); onClick('rion-spirit')" type="image" src="assets/img/icono_scroll.png">
                    </p>
                    <br><br><br><br>

                </div>
            </div>
        </div>
    </div>
</div>

<app-team></app-team>

<app-strategy></app-strategy>

<app-welcome></app-welcome>

<app-about></app-about>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-blog></app-blog>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-partner></app-partner>

<app-subscribe></app-subscribe>


<app-contact></app-contact>