<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <img src="assets/img/RaiOn_Logo.svg" alt="RaiOn" height="100" width="120" class="img-raion">
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <nav class="menuCSS3">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">HOME</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('our-products')">OUR PRODUCTS</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">ABOUT US</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">CONTACT</span></li>
                <li class="nav-item"><span class="nav-link" style="color: #FBBD2E;" (click)="onClick('downloads')">DOWNLOADS</span></li>
                 
            </ul>
        </nav>

            <ul class="navbar-nav ml-left for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">HOME</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('our-products')">OUR PRODUCTS</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">ABOUT US</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">CONTACT</span></li>
                <li class="nav-item"><span class="nav-link" style="color: #FBBD2E;" (click)="onClick('downloads')">DOWNLOADS</span></li>
            </ul>
        </div>
    </div>
</nav>