import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ContactFormService } from 'src/app/contact-form.service';
import {UntypedFormBuilder, FormControl, Validators, UntypedFormGroup, UntypedFormArray, FormBuilder} from '@angular/forms';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    public formularioEnviado = false;
    public formLoading = false;
    public isSubmitted = false;

    contactForm = this.formBuilder.group({
        full_name:['', Validators.required],
        email:['', [Validators.required, Validators.email]], 
        company_name:['', Validators.required], 
        message:['', Validators.required], 
    });
ngForm: any;

    constructor(private viewportScroller: ViewportScroller, private contactService: ContactFormService, public formBuilder: FormBuilder) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

  

    submit(){
        this.formLoading = true;
        this.isSubmitted = true;
        if (this.contactForm.invalid) {
            console.log(this.contactForm);
            this.formLoading = false;
            return;
          }
        

        this.contactService.sendForm(this.contactForm.value).subscribe({
            next: (r: any) => {
              console.log(r)
              this.formularioEnviado = true
              this.formLoading = false

            },
            error: (e) => console.log(e)
            
          })
    }

}