<div class=" bg-F7F5F4 ptb-100" id="downloads">
    <div class="container">
        <div class="section">
            <p class="section-title"><span style="color:#FBBD2E">DOWNLOADS</span></p>
        </div>
</div>
<div class="strategy-area bg-fffdfd " id="downloads">
    <div class="container">
        <div class="row strategy-title  bg-fffdfd pt-100">
            
            <div class="col-lg-6 col-md-12 container-fluid" id="rion-j5">
                <div class="strategy-content  ptb-70">
                    <div class="section-title">
                        <h4 class="strategy-content-t1 title-blog">RaiOn J5</h4>
                    </div>
                    <button class="btn-tecnhnical downloads-link"><a class="href" href="/assets/pdf/RAION_J5_Datasheet.pdf" download="RAION_J5" >DATASHEET RAION J5</a><i class="fa fa-download idwn"></i></button><br>
                                <br>
                                <button class="btn-tecnhnical downloads-link"><a class="href" href="/assets/pdf/RAION_J5_Quick-Installation-Guide.pdf" download="RAION_J5_Quick-Installation-Guide">QUICK GUIDE INSTALLATION RAION J5</a><i class="fa fa-download idwn"></i></button>
                                <br><br>
                                <button class="btn-tecnhnical downloads-link"><a class="href" href="/assets/pdf/RAIONJ5_Manual.pdf" download="RAIONJ5_Manual">MANUAL RAION J5</a><i class="fa fa-download idwn"></i></button>

                </div>
            </div>
            <div class="col-lg-6 col-md-12 bg-fffdfd">
                <div class="strategy-content  ptb-70">
                    <div class="section-title">
                        <h4 class="strategy-content-t1 title-blog">RaiOn M8</h4>
                    </div>                    <button class="btn-tecnhnical downloads-link"><a class="href" href="/assets/pdf/RAION_M8_Datasheet.pdf" download="RAION_M8" >DATASHEET RAION M8</a><i class="fa fa-download idwn"></i></button><br>
                                <br>
                                <button class="btn-tecnhnical downloads-link"><a class="href" href="/assets/pdf/RAION_M8_Quick-Installation-Guide.pdf" download="RAION_M8_Quick-Installation-Guide2" >QUICK GUIDE INSTALLATION RAION M8</a><i class="fa fa-download idwn"></i></button><br>
                                <br>
                                <button class="btn-tecnhnical downloads-link2"><a class="href" href="/assets/pdf/RAIONM8_Manual.pdf" download="RAIONM8_Manual">MANUAL RAION M8</a><i class="fa fa-download idwn"></i></button>
                </div>
            </div>
        </div>
`</div>
