<div class=" degrade bg-fffdfd pt-100" id="rion-spirit">
    <div class="container">
        <div class="section">
            <div><p class=" text-center"><span style="color:#FBBD2E">RAION SPIRIT</span></p></div>
                   <div class="text-center about-us-p"><p>We are raiOn and we are close to you: Both geographically and personally.</p></div>
            <div class="t3">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="why-we-different-img"><br><br>
                        <img src="assets/img/iconotrazado.png" alt="img">
                    </div>
                </div>
                        
                <div class="col-lg-6 col-md-12">
                    <div class="why-we-different-content">
                        <p>Based in Nairobi, we are the result of years of actively listening to underserved communities, noting their needs and working hard to develop the products and services they truly need. <br><br> Our logistic and service teams are locally based, ensuring the best product experience.</p>
                    </div>
                    <!-- <p><span style="color:#FBBD2E"> <br><br> More Information > </span></p> -->
                </div>
                
            </div>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Raion_Galeria1.jpg" alt="team-img">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Raion_Galeria2.jpg" alt="team-img">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Raion_Galeria3.jpg" alt="team-img">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Raion_Galeria4.jpg" alt="team-img">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Raion_Galeria5.jpg" alt="team-img">
                        </div>
                    </div>
                    </ng-template>
                
            </owl-carousel-o>
        </div>
    </div>
    </div>
</div>
